import { type ComponentPropsWithoutRef } from 'react'
import { getIcon } from '@components/icons'
import type { Menu } from '@lib/types'
import NavLink from '@components/nav-link'
import Analytics, { type AnalyticsProps } from '@components/analytics'
import FooterNewsletter from '@components/footer-newsletter'
import { Button, Container, Icon } from '@shc/ui'

export interface FooterProps extends ComponentPropsWithoutRef<'footer'> {
  menu: Menu
  socialMenu: Menu
  copyrightDate?: Date
  footerPromo?: boolean
  childrenPosition?: 'start' | 'end'
}

const Footer = ({
  menu,
  socialMenu,
  copyrightDate = new Date(),
  footerPromo = true,
  children,
  childrenPosition = 'start',
  ...props
}: FooterProps) => {
  return (
    <footer {...props}>
      {/* Footer Nav */}
      <div className="bg-gray-50">
        <Container as="nav" className="grid grid-cols-12 pb-8 gap-x-5">
          {childrenPosition === 'start' && (
            <div className="col-span-12 pt-10 sm:col-span-6 lg:col-span-3 md:pt-12">{children}</div>
          )}
          {menu.map(([link, children], idx1) => (
            <div key={idx1} className="col-span-12 pt-10 sm:col-span-6 lg:col-span-3 md:pt-12">
              <span className="text-sm font-semibold text-gray-700">{link.name}</span>
              <ul>
                {children.map(([link2], idx2) => (
                  <li key={idx2} className="mt-2">
                    <Analytics
                      click={{
                        name: 'navigation_click',
                        data: {
                          navigation_tree: `${link.name} > ${link2.name}`,
                          navigation_subject: link2.name,
                          navigation_level: 1,
                          navigation_url: link2.route,
                        },
                      }}
                      contexts={[{ name: 'section', data: { section_name: 'footer' } }]}>
                      <NavLink
                        className="text-sm"
                        href={link2.route}
                        target={link2.isInternal ? undefined : '_blank'}
                        rel={link2.isInternal ? undefined : 'noopener noreferrer'}>
                        {link2.icon !== undefined && (
                          <Icon icon={getIcon(link2.icon)} className="pr-1.5" />
                        )}
                        {link2.name}
                      </NavLink>
                    </Analytics>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {childrenPosition === 'end' && (
            <div className="col-span-12 pt-10 sm:col-span-6 lg:col-span-3 md:pt-12">{children}</div>
          )}
        </Container>
      </div>

      {/* Footer Promo */}
      {footerPromo && (
        <div className="bg-primary-50">
          <Container className="flex flex-col md:flex-row justify-evenly">
            <div className="flex flex-col py-8 border-b border-white basis-1/2 md:py-12 md:border-r md:border-b-0">
              <p className="font-semibold text-center">Follow us</p>
              <ul className="flex flex-row gap-x-2.5 mt-3 justify-center">
                {socialMenu.map(([link], idx) => (
                  <li key={idx}>
                    <Analytics
                      click={{
                        name: 'component_click',
                        data: {},
                      }}
                      contexts={[
                        { name: 'section', data: { section_name: 'footer' } },
                        {
                          name: 'component',
                          data: {
                            component_text: link.name,
                            component_url: link.route,
                          },
                        },
                      ]}>
                      <Button
                        href={link.route}
                        size="sm"
                        shape="circle"
                        width="auto"
                        variant="outlined"
                        title={link.name}
                        target="_blank"
                        rel="noreferrer noopener">
                        <Icon icon={getIcon(link.icon, 'iconBrand')} className="h-5" />
                      </Button>
                    </Analytics>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col py-8 border-t border-white basis-1/2 md:py-12 place-items-center md:border-l md:border-t-0">
              <FooterNewsletter />
            </div>
          </Container>
        </div>
      )}

      {/* Footer Legal */}
      <Container className="grid grid-cols-12 py-6 gap-x-5 gap-y-4">
        <div className="col-span-12 lg:col-span-8 xl:col-span-9">
          <p className="text-sm text-left text-gray-700">
            Sharp is not-for-profit and relies on fundraising. &copy;{' '}
            {copyrightDate.getUTCFullYear()} Sharp HealthCare. All rights reserved.
          </p>
        </div>
        <div className="col-span-12 lg:col-span-4 xl:col-span-3">
          <div className="text-left lg:text-right lg:whitespace-nowrap">
            <Analytics
              click={{
                name: 'navigation_click',
                data: {
                  navigation_tree: 'Privacy policy',
                  navigation_level: 1,
                  navigation_subject: 'Privacy policy',
                  navigation_url: '/policies-procedures/privacy-practices',
                },
              }}
              contexts={[{ name: 'section', data: { section_name: 'footer' } }]}>
              <NavLink
                href="/policies-procedures/privacy-practices"
                className="text-sm !font-semibold">
                Privacy policy
              </NavLink>
            </Analytics>
            <span className="px-2 text-sm text-gray-200">|</span>
            <Analytics
              click={{
                name: 'navigation_click',
                data: {
                  navigation_tree: 'Terms of use',
                  navigation_level: 1,
                  navigation_subject: 'Terms of use',
                  navigation_url: '/policies-procedures/terms-of-use',
                },
              }}
              contexts={[{ name: 'section', data: { section_name: 'footer' } }]}>
              <NavLink href="/policies-procedures/terms-of-use" className="text-sm !font-semibold">
                Terms of use
              </NavLink>
            </Analytics>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
