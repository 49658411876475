import Image from 'next/image'
import type { MouseEvent } from 'react'
import Head from 'next/head'
import Header from '@components/header'
import type { HeaderProps } from '@components/header'
import Footer from '@components/footer'
import type { FooterProps } from '@components/footer'
import type { Preview, TypeAlert } from '@lib/types'
import { site, twitter } from '@lib/constants'
import SvgRenderer from '@cms/renderers/svg-renderer'
import NavLink from '@components/nav-link'
import ErrorBoundary from '@components/error-boundary'
import AlertBar from '@components/alert-bar'
import { Alert, Button, Container, Link, Logo } from '@shc/ui'
import useAnalytics from '@hooks/use-analytics'
import Analytics from '@components/analytics'

export interface GiveLayoutProps extends Preview {
  header: HeaderProps
  alerts?: TypeAlert[]
  footer: FooterProps
  children: React.ReactNode
  title?: string
  description?: string
  seoTitle?: string
  seoDescription?: string
}

export const GiveLayout = ({
  preview,
  alerts,
  header,
  footer,
  children,
  title,
  description,
  seoTitle,
  seoDescription,
}: GiveLayoutProps) => {
  const titleFinal = `${seoTitle ?? title ?? site.name} | ${site.title}`
  const descriptionFinal = seoDescription ?? description ?? site.description
  const { track } = useAnalytics()

  const cta = (
    <Button
      href="/give/give-now"
      size="sm"
      width="auto"
      className="w-[140px] hidden lg:inline-flex"
      onClick={(e: MouseEvent<any>) => {
        track({
          event: {
            name: 'navigation_click',
            data: {
              navigation_tree: 'Give now',
              navigation_level: 1,
              navigation_subject: 'Give now',
              navigation_url: e.currentTarget.href,
            },
          },
          contexts: [{ name: 'section', data: { section_name: 'header' } }],
        })
      }}>
      Give now
    </Button>
  )

  const drawerHeader = (
    <>
      <h5 className="flex flex-row items-center p-5 text-gray-700">
        {' '}
        <span className="font-semibold">Welcome</span>
      </h5>
      <Button
        href="/give/give-now"
        className="my-3 mr-5"
        size="sm"
        width="auto"
        onClick={(e: MouseEvent<any>) =>
          track({
            event: {
              name: 'navigation_click',
              data: {
                navigation_tree: 'Give now',
                navigation_level: 1,
                navigation_subject: 'Give now',
                navigation_url: e.currentTarget.href,
              },
            },
            contexts: [{ name: 'section', data: { section_name: 'menu_drawer' } }],
          })
        }>
        Give now
      </Button>
    </>
  )

  return (
    <>
      <Head>
        <title>{titleFinal}</title>
        <meta key="description" name="description" content={descriptionFinal} />

        {/* Twitter */}
        <meta key="twitterSite" name="twitter:site" content={twitter.site} />

        {/* Open Graph */}
        <meta key="ogTitle" property="og:title" content={titleFinal} />
        <meta key="ogDescription" property="og:description" content={descriptionFinal} />
      </Head>
      <Header
        {...header}
        cta={cta}
        drawerHeader={drawerHeader}
        logoLink="/give"
        searchUrl="/give/search"
        baseSearchFilter="site:give">
        <Container className="[&>*]:my-3">
          {preview && (
            <Alert data-testid="preview-alert">
              PREVIEW session started; review unpublished changes or{' '}
              <Link href="/custom-api/preview-exit" color="dark">
                end preview session
              </Link>
              .
            </Alert>
          )}
          <AlertBar alerts={alerts} />
        </Container>
      </Header>
      <main className="flex-grow">
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      <Footer {...footer} childrenPosition="end" footerPromo={false}>
        <div className="flex flex-col gap-5">
          <Analytics
            click={{
              name: 'navigation_click',
              data: {
                navigation_tree: 'Home',
                navigation_level: 1,
                navigation_subject: 'Logo',
                navigation_url: '/',
              },
            }}
            contexts={[{ name: 'section', data: { section_name: 'footer' } }]}>
            <div className="flex flex-row gap-2 items-center">
              <Button size="xs" shape="circle" aria-label="Sharp" href="/" width="auto">
                <Logo
                  variant="icon"
                  color="inherit"
                  aria-hidden="true"
                  className="text-white h-5"
                />
              </Button>
              <NavLink href="/" className="text-sm !font-semibold">
                Visit sharp.com
              </NavLink>
            </div>
          </Analytics>
          <div className="flex flex-row gap-5 items-center">
            <SvgRenderer
              svgType="CandidPlatinumTransparency"
              className="text-campaign-secondary-800 h-[62px] w-[62px]"
              title="Associate for Healthcare Philanthropy High Performer 2021 logo"
            />
            <SvgRenderer
              svgType="RogHighPerformer"
              className="text-campaign-secondary-800 h-[72px] w-[72px]"
              title="Guidestar Platinum Transparency 2021 logo"
            />
          </div>
        </div>
      </Footer>
    </>
  )
}

export default GiveLayout
